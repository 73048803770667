import { SingleSalesGroupResource } from 'api-client'
import {
  PermissionAttributes,
  PermissionResource,
  RoleType,
  SingleIncludedUserResourcePermissionResource,
} from 'api-user-client'

interface Response<T> {
  data: T | undefined
  status: number
  success: boolean
}
async function request<T>(
  url: string,
  token: string,
  params: Record<string, string> = {},
): Promise<Response<T>> {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  }

  const response = await fetch(url, config)

  if (response.status === 200) {
    const data = await response.json()
    return { data: data as T, status: response.status, success: true }
  } else {
    console.error('Request failed', response)
    return { data: undefined, status: response.status, success: false }
  }
}

export const CSRRoles = [RoleType.Csr, RoleType.Admin, RoleType.SuperAdmin]

export const hasOneOfPermissions = (
  permissionsToCheck: RoleType[],
  permissions: PermissionResource[],
) =>
  permissionsToCheck.some(role =>
    permissions.find(p => p.attributes.role === role),
  )

export const getUser = async (token: string) => {
  const response = await request<SingleIncludedUserResourcePermissionResource>(
    `/api-user/me`,
    token,
    {
      include: 'permissions',
    },
  )

  if (!response.success) throw new Error('Failed retrieving user')
  return response.data
}

export function getUserPermissions(
  user: SingleIncludedUserResourcePermissionResource,
) {
  const userPermissions = user.included

  if (!userPermissions || userPermissions.length === 0) {
    throw new Error('User does not have any permissions')
  }

  return userPermissions
}

export function getFirstPermissionAttributes(
  permissions: PermissionResource[],
) {
  const firstPermission = permissions[0]?.attributes

  if (!firstPermission) {
    throw new Error('Permission does not have any attributes')
  }

  return firstPermission
}

export async function getSalesGroup(
  permission: PermissionAttributes,
  token: string,
) {
  const { role, salesGroupId } = permission

  if (role !== RoleType.SuperAdmin && !salesGroupId)
    throw new Error('Permission does not contain a salesgroup')

  const response = await request<SingleSalesGroupResource>(
    `http://localhost:8000/api/sales-groups/${salesGroupId}`,
    token,
  )

  if (!response.success) throw new Error('Failed retrieving sales group')

  return response.data
}

export const isValidUser = (
  user: SingleIncludedUserResourcePermissionResource,
) =>
  user.data.attributes.status === 'Active' &&
  user.included &&
  user.included.length >= 1

export function redirectToClient() {
  window.location.replace('/connect')
}

export function redirectToCSR() {
  window.location.replace('/csr-connect')
}

export const getTemplatedConfigOrDefault = (value: string, fallback: string) =>
  value && !value.endsWith('%>') ? value : fallback
