/* eslint-disable @typescript-eslint/camelcase */
import createAuth0Client from '@auth0/auth0-spa-js'
import { parse } from 'querystring'

import {
  CSRRoles,
  getTemplatedConfigOrDefault,
  getUser,
  getUserPermissions,
  hasOneOfPermissions,
  isValidUser,
  redirectToClient,
  redirectToCSR,
} from './utils'

const Auth0Audience = getTemplatedConfigOrDefault(
  '<%= $AUTH0_AUDIENCE %>',
  process.env.AUTH0_AUDIENCE,
)
const Auth0ClientId = getTemplatedConfigOrDefault(
  '<%= $AUTH0_CLIENT_ID %>',
  process.env.AUTH0_CLIENT_ID,
)
const Auth0Domain = getTemplatedConfigOrDefault(
  '<%= $AUTH0_DOMAIN %>',
  process.env.AUTH0_DOMAIN,
)

const configureClient = async () => {
  try {
    // strip out the leading question mark
    const queryString = window.location.search.substring(1)
    const query = parse(queryString)
    const { code, error_description, state } = query
    let { language } = query
    if (typeof language !== 'string') language = 'en'

    const auth0 = await createAuth0Client({
      audience: Auth0Audience,
      client_id: Auth0ClientId,
      domain: Auth0Domain,
      redirect_uri: window.location.origin,
      ui_locales: language,
    })

    const path = window.location.pathname
    const searchParams = new URLSearchParams(window.location.search)

    if (path.includes('/auth0-errors')) {
      if (error_description) {
        const message = searchParams.get('error_description')
        return auth0.loginWithRedirect({
          action: 'unauthorized',
          action_details: message,
          prompt: 'login',
        })
      } else {
        return auth0.loginWithRedirect({
          action: 'unauthorized',
          prompt: 'login',
        })
      }
    }

    if (path.includes('/oauth-redirect')) {
      const params: Record<string, string> = {}
      let action: string | undefined
      let action_details: string | undefined
      searchParams.forEach((value, key) => {
        if (['action', 'success', 'prompt'].includes(key)) {
          switch (key) {
            case 'success':
              if (value === 'false') {
                action = 'unauthorized'
                action_details = searchParams.get('message') || undefined
              }
              break
            default:
              params[key] = value
          }
        }
      })

      return auth0.loginWithRedirect({
        ...params,
        action,
        action_details,
      })
    }

    const isAuthenticated = await auth0.isAuthenticated()
    if (isAuthenticated) {
      if (path.includes('/logout')) {
        return auth0.logout({
          returnTo: `${window.location.origin}/oauth-redirect?action=logout&prompt=login`,
        })
      }

      let token
      try {
        token = await auth0.getTokenSilently()
      } catch (e) {
        token = await auth0.getTokenWithPopup()
      }

      let user = null
      try {
        user = await getUser(token)
        if (!user || !isValidUser(user)) {
          throw new Error('User is invalid')
        }
      } catch (e) {
        console.log(e)
        return auth0.loginWithRedirect({
          action: 'unauthorized',
          prompt: 'login',
        })
      }

      const userPermissions = getUserPermissions(user)

      if (hasOneOfPermissions(CSRRoles, userPermissions)) {
        redirectToCSR()
      } else {
        redirectToClient()
      }
    } else {
      if (code && state) {
        await auth0.handleRedirectCallback()
        window.location.replace('/')
      } else if (!isAuthenticated) {
        return auth0.loginWithRedirect({
          prompt: 'login',
        })
      }
    }
  } catch (error) {
    console.error(error)
  }
}

window.onload = async () => {
  await configureClient()
}
