/* tslint:disable */
/* eslint-disable */
/**
 * DigiB Core User API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum APIErrorCodes {
    ResourceExists = 'E_RESOURCE_EXISTS',
    UnknownError = 'E_UNKNOWN_ERROR',
    ResourceDoesNotExist = 'E_RESOURCE_DOES_NOT_EXIST',
    InvalidEmailAuth0 = 'E_INVALID_EMAIL_AUTH0',
    EmailExistsAuth0 = 'E_EMAIL_EXISTS_AUTH0',
    InvalidLanguage = 'E_INVALID_LANGUAGE',
    OutsideSalesGroup = 'E_OUTSIDE_SALES_GROUP'
}



