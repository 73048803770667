/* tslint:disable */
/* eslint-disable */
/**
 * DigiB Core User API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum LangCode {
    En = 'en',
    Vi = 'vi',
    Th = 'th',
    Id = 'id',
    Ko = 'ko',
    ZhTw = 'zh-TW',
    ZhCn = 'zh-CN'
}



